import './App.css';
import Onmeta from './Onmeta';

function App() {
  return (
      <Onmeta/>
  );
}

export default App;
