import React, { useEffect } from 'react';

const Onmeta = () => {
   


    useEffect(() => {
        const currentUrl = window.location.href;
        // Extract the parameter value using URLSearchParams
        const url = new URL(currentUrl);
        const userWallet = url.searchParams.get('walletAddress');
        const createWidget = new window.onMetaWidget({
            elementId: 'widget',
            apiKey: '3d970697-52da-4f45-b480-01345d78779b', // Replace with your API key
            // Add any other optional parameters here
            walletAddress: userWallet==null?'':userWallet,
            isAndroid: "enabled",
            metamask: "disabled",
            fiatType:"inr"
        });

        // console.log("Widget: ",createWidget)

        createWidget.init();


        // Example event handler
        const handleEvent = (event) => {
            // Handle the event
            console.log(event);
        };

        // Attach event listener
        createWidget.on('eventType', handleEvent);

        return () => {
            // Cleanup code if needed
            createWidget.close();
        };

    }, []);

    return <div id="widget" />;
};

export default Onmeta;
